import React from "react"

import ActionButtons from "../Elements/ActionButtons"
import HealthSurveyForm from "./HealthSurvey/HealthSurveyForm"

const HealthSurvey = ({ backRoute }) => (
  <HealthSurveyForm>
    <ActionButtons
      submit={{ label: "Review Details" }}
      back={{ label: "Back", link: backRoute }}
    />
  </HealthSurveyForm>
)

export default HealthSurvey
