import React, { useContext } from "react"
import { navigate } from "gatsby"
import { Formik, Form } from "formik"

import InvalidFormMessage from "../../Elements/InvalidFormMessage"

import { AppContext } from "../../../context/AppContext"
import { healthSurveyValidationSchema } from "../utils/formData"
import { prescribedMedicineChoices } from "../../Enrollment/utils/formData"

import homeAdministrationFields from "./utils/homeAdministrationFields.json";
import { generateFormField } from "../../Elements/Form/services/form";

const HealthSurveyForm = ({ children }) => {
  const { state, dispatch } = useContext(AppContext)
  const { healthSurvey, medicines } = state.epharmacy

  const handleOnSubmit = async (values) => {
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, healthSurvey: { ...values } },
      },
    })
    await navigate("/epharmacy/summary")
  }

  let prescribedMedicine = prescribedMedicineChoices.find(
    (medicine) => medicine.sku === medicines[0]?.productTitle
  )

  return (
    <Formik
      initialValues={{
        ...healthSurvey,
        medicinePrescribed: prescribedMedicine,
        isMedicineSolid: prescribedMedicine?.isSolid,
      }}
      onSubmit={handleOnSubmit}
      validationSchema={healthSurveyValidationSchema}
    >
      {({ values, setFieldValue, isValid, submitCount, onChange }) => (
        <Form>
          {homeAdministrationFields.map((field) =>
            !field?.referenceAnswer
              ? generateFormField({
                formField: field,
                formFields: homeAdministrationFields,
                values,
                setFieldValue,
                onChange
              })
              : null
          )}
          {!isValid && submitCount > 0 && (
            <InvalidFormMessage color="danger" name="missingRequiredFields" />
          )}
          {children}
        </Form>
      )}
    </Formik>
  )
}

export default HealthSurveyForm
