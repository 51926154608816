import React from "react"
import Layout from "../../components/Layout/Layout"
import Container from "../../components/Layout/Container"
import HealthSurvey from "../../components/Epharmacy/HealthSurvey"

export default () => (
  <Layout
    title="Health Survey"
    subtitle=""
    process="epharmacy"
    seoTitle="Health Survey"
    display={{ footer: false, helpCenterBanner: false }}
    isPrivate
    isPatient
  >
    <Container isCentered desktop={6} fullhd={6}>
      <HealthSurvey backRoute="/epharmacy/shipping-and-payment" />
    </Container>
  </Layout>
)
